<template>
  <p>
    <span class="time mr-1">{{ formatDate(notification.creationDate, { hour: 'numeric', minute: 'numeric' }) }}</span>
    <span class="font-weight-bolder">{{ notification.title }}</span>
    -
    <small class="notification-text">{{ notification.message }}</small>
  </p>
</template>

<script>
import { formatDate } from '@core/utils/filter'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
}
</script>
