<template>
  <div>
    <div v-for="(notifications, date) in groupedNotifications" :key="date">
      <h3>{{ date }}</h3>

      <div v-for="notification in notifications" :key="notification.id">
        <b-link v-if="notification.url" :to="{ path: notification.url }">
          <NotificationMessage :notification="notification" />
        </b-link>
        <NotificationMessage v-else :notification="notification" />
        <hr />
      </div>
    </div>

    <!-- pagination -->
    <div v-if="pagination.totalPages > 1" class="my-2">
      <b-pagination
        v-model="currentPage"
        :total-rows="pagination.totalElements"
        :per-page="pagination.perPage"
        align="center"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { ref, watch } from '@vue/composition-api'

import { formatDate } from '@core/utils/filter'
import { BPagination, BLink } from 'bootstrap-vue'
import NotificationMessage from './NotificationMessage.vue'

export default {
  components: {
    BPagination,
    BLink,
    NotificationMessage,
  },
  setup() {
    const groupedNotifications = ref([])

    const currentPage = ref(1)

    const pagination = ref({
      totalPages: 1,
      totalElements: 0,
      perPage: 25,
    })

    const fetchData = () => {
      axios
        .get('notifications/search', {
          params: {
            page: currentPage.value - 1,
            size: pagination.value.perPage,
          },
        })
        .then(res => {
          const dates = {}

          currentPage.value = res.data.number + 1
          pagination.value.totalPages = res.data.totalPages
          pagination.value.totalElements = res.data.totalElements
          pagination.value.perPage = res.data.size

          res.data.content.forEach(notification => {
            const key = formatDate(notification.creationDate)

            if (dates[key]) {
              dates[key].push(notification)
            } else {
              dates[key] = [notification]
            }
          })

          groupedNotifications.value = dates
        })
    }

    fetchData()
    watch(currentPage, fetchData)

    return {
      // Filter
      formatDate,
      pagination,
      currentPage,
      groupedNotifications,
    }
  },
}
</script>

<style lang="scss" scoped>
.time {
  margin-right: 8px;
}
</style>
